import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import UIStore from '../../store/UIStore'
import { _T } from '../../_i18n/i18n.helper'

import ProjectStore from '../../store/ProjectStore'
import formStore from './form.store'
import Field from '../../_components/forms/Field'
import manageForm from '../../_components/forms/manageForm'
import * as clientService from '../../Client/client.service'
import * as projectService from '../project.service'
import * as apiService from '../../_services/api.service';
import { toast } from 'react-toastify'
import SweetAlert from 'react-bootstrap-sweetalert'


const SaveProjectModal = (props) => {
  const { errors, touched, onChange, onBlur, modalIsOpen, toggle, showGenerateButton } = props; // NOSONAR

  const [data, setData] = useState({
    showClients: false,
    clients: null,
    filteredClients: null,
    searchInput: '',
    showAlert: false,
    clientToDelete: null,
    showSynthesisTitle: null
  });

  useEffect(() => {
    async function fetchData() {
      const settings = await apiService.get(`parameters/env=${UIStore.environnement_id}`);

      let Modulation = settings.filter((i) => i.code === 'Modulation')[0];

      if(Modulation) {
        Modulation = settings.filter((i) => i.code == 'Modulation')[0].value;
      }

      setData(data => {return {...data, showSynthesisTitle: Modulation}});
      
      if (modalIsOpen) {
        if (ProjectStore.project.id && ProjectStore.project.header) {
          formStore.form = { ...ProjectStore.project.header }
        }
      }
    }

    fetchData();
  }, [modalIsOpen])

  const clientSearch = async () => {
    let clients;

    if (!data.showClients) {
       clients = await clientService.getAll()
    }
    
    setData({
      ...data,
      clients: clients,
      filteredClients: clients,
      showClients: !data.showClients,
    })
  }

  const refreshClient = async () => {
    const clients = await clientService.getAll()

    setData({
      ...data,
      clients: clients,
      filteredClients: clients,
      showAlert: false,
      clientToDelete: null
    })
  }

  const toogleClients = () => {
    setData({
      ...data,
      showClients: !data.showClients,
    })
  }

  const selectClient = (e, client) => {
    formStore.setClient(client)
    toogleClients()
  }

  const onConfirmDelete = () => {
    clientService.destroy(data.clientToDelete).then((response) => {
      refreshClient()
    })

    setData({
      ...data,
      showAlert: false,
    })
  }

  const onCancel = () => {
    setData({
      ...data,
      showAlert: false,
      clientToDelete: null
    })
  }

  const deleteClient = (e, client) => {
    e.stopPropagation()
    setData({
      ...data,
      showAlert: true,
      clientToDelete: client
    })
  }

  const handleSearchInputChange = (e) => {
    const searchInput = e.target.value.toLowerCase()

    const filteredClients_ = (searchInput === ''
      ? data.clients
      : data.clients.filter(
        (c) =>
          (c.data.companyName?.toLowerCase().includes(searchInput)) ||
          (c.data.name?.toLowerCase().includes(searchInput)) ||
          (c.data.address?.toLowerCase().includes(searchInput)) ||
          (c.data.cp?.toLowerCase().includes(searchInput)) ||
          (c.data.city?.toLowerCase().includes(searchInput)) ||
          (c.data.phone?.toLowerCase().includes(searchInput))
      ))

      setData({
        ...data,
        searchInput: searchInput,
        filteredClients: filteredClients_
      })
  }

  const generate = () => {
    if (props.handleSaveModalResponse) props.handleSaveModalResponse({ header: formStore.form }) // NOSONAR
    props.toggle(); // NOSONAR
  }

  const save = () => {
    let breackChainError = false
    return props
      .validate() // NOSONAR
      .then(() => {
        return projectService
          .save(formStore.form)
          .then(() => {
            debugger
            setTimeout(() => toast.success(_T('PROJECT_SAVED')), 500)
            
            props.toggle(); // NOSONAR

            ProjectStore.setHeader(formStore.form)
            ProjectStore.saveStore()

            if (props.handleSaveModalResponse) props.handleSaveModalResponse({}) // NOSONAR

            return Promise.resolve()
          })
          .catch((err) => {
            if (!breackChainError) {
              toast.error(_T('FRONT_SAVE_ERROR'))
              breackChainError = true
            }
            return Promise.reject(err)
          })
      })
      .catch((err) => {
        if (!breackChainError) {
          toast.error(_T('SOME_FIELDS_NEED_YOUR_ATTENTION'))
          breackChainError = true
        }
        return Promise.reject(err)
      })
  }

  const cancel = () => {
    props.toggle(); // NOSONAR
    if (props.handleSaveModalResponse) props.handleSaveModalResponse(null) // NOSONAR
  }

  const onChangeNumberOnly = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '') // NOSONAR
    props.onChange(e); // NOSONAR
  }

  const onChangeTextOnly = (e) => {
    e.target.value = e.target.value.replace(/[^A-zÀ-ú0-9 +-_']/gi, '') // NOSONAR
    props.onChange(e); // NOSONAR
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} toggle={toggle} size="lg" className="modalSaveProject">
        <ModalBody>
          <button 
            className="btn_close" //NOSONAR
            onClick={toggle} //NOSONAR
          >
            X
          </button>
          <div>
            <div className="row" //NOSONAR
            >
              <div className="col-12 col-lg-6" //NOSONAR
              >
                <div className="row" //NOSONAR
                >
                  <p>{_T('PROJECT_INFOS')}</p>
                </div>
                <div className="row" //NOSONAR
                >
                  <div className="space" //NOSONAR
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="technician"
                      placeholder={_T('TECHNICIAN')}
                      max="25"
                      onChange={onChangeTextOnly}
                      onBlur={onBlur}
                      value={formStore.form.technician}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="space spacex2" //NOSONAR
                  >
                    <Field
                      className="form-control"
                      type="text"
                      name="projectName"
                      placeholder={_T('PROJECT_NAME')}
                      max="20"
                      onChange={onChangeNumberOnly}
                      onBlur={onBlur}
                      value={formStore.form.projectName}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <Field
                    className="form-control"
                    type="text"
                    name="projectRef"
                    placeholder={_T('PROJECT_REF')}
                    max="20"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.projectRef}
                    errors={errors}
                    touched={touched}
                  />
                  {data.showSynthesisTitle === true && <Field
                    className="gray"
                    type="checkbox"
                    name={'synthesisTitle'}
                    value={formStore.form.synthesisTitle}
                    placeholder="SYNTHESIS_TITLE"
                    onChange={onChange}
                  />}
                </div>
              </div>

              <div className="col-12 col-lg-6" //NOSONAR
              >
                <div className="row" //NOSONAR
                >
                  <p className="gray" //NOSONAR
                  >{_T('CLIENT_INFOS')}</p>
                </div>
                <div className="row" //NOSONAR
                >
                  <button 
                    className="btn search" //NOSONAR
                    onClick={clientSearch} //NOSONAR
                  >
                    {_T('CLIENT_SEARCH')} {data.showClients ? '^' : '>'}
                  </button>

                  {data.showClients && data.clients && (
                    <Field
                      className="form-control"
                      type="text"
                      name="clientSearch"
                      placeholder={_T('SEARCH')}
                      onChange={handleSearchInputChange}
                      value={data.searchInput}
                    />
                  )}

                  {data.showClients && data.filteredClients && (
                    <div className="clientList" //NOSONAR
                    >
                      {data.filteredClients.map((client, index) => (
                        <div //NOSONAR
                          className="row client" //NOSONAR
                          key={index} //NOSONAR
                          onClick={(e) => selectClient(e, client)} //NOSONAR
                        >
                          <div className="col col-3 col-lg-3 breakWord" //NOSONAR
                          >{client.data.companyName}</div>
                          <div className="col col-2 col-lg-2 breakWord" //NOSONAR
                          >{client.data.name}</div>
                          <div className="col col-12 col-lg-6 breakWord" //NOSONAR
                          >
                            {client.data.address}, {client.data.cp} {client.data.city}
                          </div>
                          <div className="col col-12 col-lg-1" //NOSONAR
                          >
                            <button 
                              className="btn_delete" //NOSONAR
                              onClick={(e) => deleteClient(e, client)} //NOSONAR
                            >
                              <i></i>
                            </button>
                          </div>
                        </div>
                      ))}

                      {data.showClients && (!data.filteredClients || data.filteredClients.length <= 0) && (
                        <div className="noClients" //NOSONAR
                        >{_T('NO_CLIENTS')}</div>
                      )}
                    </div>
                  )}

                  <Field
                    className="form-control"
                    type="text"
                    name="client.data.companyName"
                    placeholder={_T('CLIENT_COMPAGNY_NAME')}
                    onChange={onChangeTextOnly}
                    max="25"
                    onBlur={onBlur}
                    value={formStore.form.client.data.companyName}
                    errors={errors}
                    touched={touched}
                  />

                  <Field
                    className="form-control"
                    type="text"
                    name="client.data.name"
                    placeholder={_T('CLIENT_NAME')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={formStore.form.client.data.name}
                    errors={errors}
                    touched={touched}
                  />

                  <Field
                    className="form-control"
                    type="text"
                    name="client.data.address"
                    placeholder={_T('CLIENT_ADDRESS')}
                    onChange={onChangeTextOnly}
                    max="30"
                    onBlur={onBlur}
                    value={formStore.form.client.data.address}
                    errors={errors}
                    touched={touched}
                  />

                  <div className="row" //NOSONAR
                  >
                    <div className="col-12 col-lg-4" //NOSONAR
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="client.data.cp"
                        placeholder={_T('CLIENT_CP')}
                        onChange={onChangeNumberOnly}
                        max="10"
                        onBlur={onBlur}
                        value={formStore.form.client.data.cp}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                    <div className="col-12 col-lg-8" //NOSONAR
                    >
                      <Field
                        className="form-control"
                        type="text"
                        name="client.data.city"
                        placeholder={_T('CLIENT_CITY')}
                        onChange={onChangeTextOnly}
                        max="25"
                        onBlur={onBlur}
                        value={formStore.form.client.data.city}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>

                  <Field
                    className="form-control"
                    type="text"
                    name="client.data.phone"
                    placeholder={_T('CLIENT_PHONE')}
                    onChange={onChangeNumberOnly}
                    max="15"
                    onBlur={onBlur}
                    value={formStore.form.client.data.phone}
                    errors={errors}
                    touched={touched}
                  />

                  <Field
                    className="gray"
                    type="checkbox"
                    name={'registerClient'}
                    value={formStore.form.registerClient}
                    placeholder="REGISTER_CLIENT"
                    onChange={onChange}
                  />

                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn enregistrer" //NOSONAR
           onClick={save} //NOSONAR
          >
            {showGenerateButton ? _T('NAV_SAVE_AND_GENERATE') : _T('NAV_SAVE')}
          </button>
          {showGenerateButton && (
            <button className="btn enregistrer" //NOSONAR
              onClick={generate} //NOSONAR
            >
              {_T('GENERATE')}
            </button>
          )}
          <button className="btn cancel" //NOSONAR
            onClick={cancel} //NOSONAR
          >
            {_T('CANCEL')}
          </button>
        </ModalFooter>
      </Modal>

      {data.showAlert && (
        <SweetAlert
          customClass="sweetAlertCustom"
          warning
          showCancel
          confirmBtnText={_T('YES')}
          confirmBtnBsStyle="danger"
          cancelBtnText={_T('NO')}
          title={_T('FRONT_DELETE_CLIENT')}
          onConfirm={onConfirmDelete}
          onCancel={onCancel}
          focusCancelBtn
        >
          {_T('DELETE_WARNING')}
        </SweetAlert>
      )}
    </>
  )
}

export default manageForm(observer(SaveProjectModal), formStore, 'form')
