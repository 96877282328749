import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'
import jsonLogic from 'json-logic-js'

import NavigationButtons from '../_components/Navigation/NavigationButtons'
import { _T, _THtml } from '../_i18n/i18n.helper'

import ProjectStore from '../store/ProjectStore'
import * as luaService from './lua.service'
import Acc from './StepAcc/Acc'
import MainMenu from '../_components/Navigation/MainMenu'
import Breadcrumb from '../_components/Navigation/Breadcrumb'

import UIStore from '../store/UIStore'

import fleche_titre_atlantic from '../assets/img/fleche-titre.svg'
import fleche_titre_thermorfr from '../assets/img/fleche-titre-thermorfr.svg'
import fleche_titre_pacific from '../assets/img/fleche-titre-pacific.svg'

const imgChevrons = {
  atlantic: fleche_titre_atlantic,
  thermorfr: fleche_titre_thermorfr,
  pacific: fleche_titre_pacific
}

/**
 * Project page
 */

const ProjectStepAcc = (props) => {
  const [state, setState] = useState({
    selectedGroup: null,
    recommendedAccessories: [],
    otherAccessories: [],
    accessoriesGroups: [],
  })

  const fleche_titre = imgChevrons[UIStore.environment_parameters.brand] || fleche_titre_atlantic

  let currentDep = []

  useEffect(() => { //NOSONAR
    async function fetchAccessories() {

      let oldAccessories;
      if (ProjectStore.project.accessories) { // NOSONAR
        oldAccessories = ProjectStore.project.accessories
      }

      let accessories = await luaService.getAccessories(ProjectStore.project.selectedProduct.product.code_env);

      if (oldAccessories) { // NOSONAR
        oldAccessories = oldAccessories['recommendedAccessories'].slice().concat(oldAccessories['otherAccessories'].slice())

        accessories.forEach((acc) => {
          let found = oldAccessories.find((oacc) => oacc.id === acc.id)
          if (found) { // NOSONAR
            acc._selected = found._selected
            acc._qty = found._qty
          }
        })
      }

      if (accessories) { // NOSONAR
        let groups = {};
        let sortedAccessories = sortAccessories(accessories);

        accessories.forEach((acc) => {
          if (!acc._recommended && !groups[acc._accessory._accessorygroup_description]) { // NOSONAR
            groups[acc._accessory._accessorygroup_description] = {
              id: acc._accessory.accessorygroup_id,
              order: acc._accessory_group_order,
              label: acc._accessory._accessorygroup_description,
            }
          }
        })

        const accessoriesGroups = Object.values(groups)
        accessoriesGroups.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const A = a.order
          const B = b.order

          let comparison = 0
          if (A > B) { // NOSONAR
            comparison = 1
          } else if (A < B) { // NOSONAR
            comparison = -1
          }
          return comparison;
        })

        setState({
          accessoriesGroups: accessoriesGroups,
          recommendedAccessories: sortedAccessories.recommendedAccessories,
          otherAccessories: sortedAccessories.otherAccessories,
          selectedGroup: accessoriesGroups[0].id,
        })
      }
    }
    fetchAccessories();
  }, []);

  const sortAccessories = (accessories) => {
    let recommendedAccessories = []
    let otherAccessories = []
    const data = toJS(ProjectStore.project)

    accessories.forEach((acc) => {
      let result

      try {
        result = acc._accessory.select_rules
          ? jsonLogic.apply(
            JSON.parse(acc._accessory.select_rules), // Rule
            data, // Data
          )
          : false
      } catch (e) {
        result = false
        console.log('Err parsing accessory ' + acc._accessory.code + ' rules')
      }

      try {
        if (acc._accessory.quote_rules) {
          const test = acc._accessory.quote_rules
            ? jsonLogic.apply(
              JSON.parse(acc._accessory.quote_rules), // Rule
              data, // Data
            )
            : false

          if (result === test) {
            acc._qty = ProjectStore.project.forms.heatModeStep.form.zones.length
          } else {
            acc._qty = 1
          }
        }
      } catch (e) {
        console.log('Err parsing accessory ' + acc._accessory.code + ' rules')
      }

      otherAccessories.push(acc)
    })

    return { recommendedAccessories, otherAccessories }
  }

  /**
   * Check if one selected accessory depend on "acc"
   * We can't unselect an accessory which has selected accessories that depend on it
   * @param acc
   * @returns {*}
   */
  const checkAccessoryReverveDependency = (acc) => {
    let reverveDependencyDetected = null

    const accessoryLists = [state.recommendedAccessories, state.otherAccessories]

    accessoryLists.forEach((list) => {
      list.forEach((a) => {
        if (a.dep && a.dep.length > 0) {
          a.dep.forEach((dep) => {
            if (a._selected && dep === acc.id) {
              reverveDependencyDetected = { parent: { id: acc.id, code_env: acc.code_env }, child: { id: a.id, code_env: a.code_env } }
            }
          })
        }
      })
    })

    return reverveDependencyDetected
  }

  /**
   * Check if an accessory depend on other accessories
   * Check if an accessory is incomatible with already selected accessory
   * @param acc
   * @param selectMode -> auto check depend accessories
   * @returns {*}
   */
  const checkAccessoryDependency = (acc, selectMode) => { //NOSONAR
    currentDep.push('' + acc.id)

    let incompatibilityDetected = null

    const accessoryLists = [state.recommendedAccessories, state.otherAccessories]

    if (acc._accessory.dep && acc._accessory.dep.length > 0) {
      acc._accessory.dep.forEach((dep) => {
        accessoryLists.forEach((list) => {
          list.forEach((a) => {
            if (a.id === dep && !currentDep.includes(dep)) {
              console.log(a.id + ', ' + dep + ', ' + currentDep.includes(dep))
              currentDep.forEach((it) => console.log('plop' + it))
              if (!a._selected) {
                if (!selectMode) {
                  incompatibilityDetected = checkAccessoryDependency(a)
                } else {
                  selectAccessory(null, a)
                  toast.success(_T('ACCESSORY_DEPENDENCY_SELECTED', { child: acc.code_env, parent: a.code_env }))
                }
              }
            }
          })
        })
      })
    }

    if (incompatibilityDetected == null) {
      if (acc._accessory.inc && acc._accessory.inc.length > 0) {
        acc._accessory.inc.forEach((inc) => {
          accessoryLists.forEach((list) => {
            list.forEach((a) => {
              if (a._selected && a.id === inc && !currentDep.includes(inc)) {
                // toast.warning(acc.id + " INC " + inc) // NOSONAR
                incompatibilityDetected = { child: { id: acc.id, code_env: acc.code_env }, parent: { id: a.id, code_env: a.code_env } }
              }
            })
          })
        })
      }
    }

    return incompatibilityDetected
  }

  const selectAccessory = (e, acc) => { //NOSONAR
    if (!acc._selected) {
      const result = checkAccessoryDependency(acc)
      if (result) {

        /**
         * if(result.child != acc.id) toast.warning(acc.id + " DEPEND DE " + result.child + ' MAIS ' + result.child + " INC AVEC " + result.parent)
         * else toast.warning(result.child + " INC AVEC " + result.parent)
         */

        const { child, parent } = result
        if (result.child.id !== acc.id)
          toast.warning(
            _T('ACCESSORY_DEPENDENCY_AND_INCOMPATIBILiTY_DETECTED', { child: child.code_env, parent: parent.code_env, acc: acc.code_env }),
          )
        else toast.warning(_T('ACCESSORY_INCOMPATIBILiTY_DETECTED', { child: child.code_env, parent: parent.code_env }))

        return
      } else {
        if (e) currentDep = []
        checkAccessoryDependency(acc, true)
      }
    } else {
      const accessoryNotDeselectable = ['700436', '700437', '700432', '700433', '700434', '075327']
      if (accessoryNotDeselectable.find((c) => c === acc.code_env)) {
        toast.warning(_T('NOT_DESELECTABLE_ACCESSORY'))
        return
      }

      const result = checkAccessoryReverveDependency(acc)
      if (result) {
        const { child, parent } = result
        //toast.warning(result.child + " DEPEND DE " + result.parent) // NOSONAR
        toast.warning(_T('ACCESSORY_DEPENDENCY_DETECTED', { child: child.code_env, parent: parent.code_env }))
        return
      }

      if (acc._recommended) {
        toast.warning(_T('UNSELECT_RECOMMENDED_ACCESSORY'))
      }
    }

    acc._selected = !acc._selected

    if (!acc._selected) {
      acc._qty = null
      acc._discount = null
    } else {
      acc._qty = 1
    }

    setState((prevState) => ({ ...prevState }))

    if (e) currentDep = []
  }

  const submit = () => {
    ProjectStore.setAccessories(state)
    ProjectStore.saveStore()
    return Promise.resolve()
  }

  const hideNextButton = () => {
    if (!UIStore.environment_parameters.disabledSteps) return false;

    try {
      const disabledStepsArray = UIStore.environment_parameters.disabledSteps.split(',')
      if (disabledStepsArray.find(ds => ds === 'quote') && disabledStepsArray.find(ds => ds === 'synthesis')) return true
    } catch (ex) {
      return false
    }
  }

  const brand = UIStore.environment_parameters.brand

  return (
    <>
      <div className="header container-fluid">
        <MainMenu />
        <Breadcrumb handleSubmit={submit} />
      </div>

      <div className="main container-fluid">
        <form id="form_stepHome" onSubmit={(e) => e.preventDefault()}>
          <div className="row donneesACC">
            <div className="col col-12 col-lg-2 titreRubrique">
              <p className={brand === "pacific" && "pacific"}>{_THtml('OTHER_ACC')}</p>
              {brand !== "pacific" && <img className="chevron" src={fleche_titre} alt="" />}
            </div>

            <div className="col col-12 col-lg-10 contentRubrique">
              <nav className="navProduits">
                {state.accessoriesGroups?.map((group) => {
                    return (
                      <div
                        key={'group' + group.id}
                        className={'navProduitsGroup'}
                      >
                        <span className={
                          state.selectedGroup === group.id ?
                            brand === "pacific" ? 'active pacific' : 'active' // NOSONAR
                            :
                            brand === "pacific" ? 'pacific' : ''}>{group.label}</span> {/* NOSONAR */}
                        <div className="row allProduits">
                          {state.otherAccessories?.map((acc, index) => { // NOSONAR
                              if (acc._accessory.accessorygroup_id !== group.id) return false
                              return <Acc 
                                key={'acc2' + index} // NOSONAR
                                acc={acc} // NOSONAR
                                select={(e) => selectAccessory(e, acc)} // NOSONAR
                              />
                            })}
                        </div>
                      </div>
                    )
                  })}
              </nav>
            </div>
          </div>
        </form>

        <NavigationButtons handleSubmit={submit} hideNext={hideNextButton()} />
      </div>
    </>
  )
}

export default observer(ProjectStepAcc)