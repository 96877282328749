import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import {_T, _THtml} from '../../_i18n/i18n.helper';
import { CHART_BARCHART_OPTIONS } from '../../configApp';
import HelpPophover from "../../_components/aide/HelpPophover";

function ProductModalContent7(props) {
  const { product } = props; // NOSONAR
  const [, setWindowSize] = useState(null); // NOSONAR

  const barChartOptions = {...CHART_BARCHART_OPTIONS,
    ...{
        title: _T('PIECHART_RAF_ANNUAL_CONFORT_TITLE'),
        isStacked: true,
        seriesType: 'bars',
        //series: {3: {type: 'line'}, 4: {type: 'line', lineDashStyle: [4, 4]}},
        interpolateNulls: true,

        // Gestion bar horizontal de référence:
        // https://stackoverflow.com/questions/23535668/drawing-visual-lines-in-google-charts
        hAxis: {
          textPosition: 'none',
          format: 'Q#',
          ticks: [1],
          viewWindow: {
            min: 0,
            max: 3
          }
        },
        vAxis: {
          title: _T('PIECHART_RAF_YAXIS'),
          titleTextStyle: { italic: false },
        },
        annotations: {
          textStyle: {
            color: 'black',
            fontSize: 11,
          },
          alwaysOutside: false
        }
      }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Fix a bug on smartphone to adapt first chart to screen size
    setTimeout(() => {
      setWindowSize(window.innerWidth);
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", handleResize);
    }
    // eslint-disable-next-line no-use-before-define
  }, [handleResize]);

  const handleResize = useCallback(() => {
    delete barChartOptions.animation;
    setWindowSize(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const drawChartCoolMode = (conso) => { // NOSONAR
  //   const Tre2020 = 26 // NOSONAR
  //   const consigne = projectStore.project.forms.coolModeStep.form.temperature // NOSONAR
  //   const showZone2 = projectStore.project.forms.coolModeStep.form.temperature < Tre2020 // NOSONAR
  //   const tempMax = Math.max(consigne, Tre2020) // NOSONAR

  //   var reference = conso.ConfortTX + conso.InteriorTX // NOSONAR

  //   // Gestion bar horizontal de référence // NOSONAR
  //   // https://stackoverflow.com/questions/23535668/drawing-visual-lines-in-google-charts // NOSONAR
  //   // VOIR configuration hAxis + les lignes chartData 0 et 3 // NOSONAR
  //   let chartData = [ // NOSONAR
  //     ['', `<${consigne}°C`, {role: 'annotation'}, {role: 'tooltip'}, `${consigne}°C-${Tre2020}°C`, {role: 'annotation'}, {role: 'tooltip'}, `>${tempMax}°C`, {role: 'annotation'}, {role: 'tooltip'}, _T('PIECHART_RAF_RT2020', {temp: Tre2020}), {role: 'tooltip'},  _T('PIECHART_RAF_TEMP_SET', {temp: consigne}), {role: 'tooltip'} ], // NOSONAR
  //     [0, null, null, null, null, null, null, null, null, null, reference*100, null, conso.ConfortTX*100, null], // NOSONAR
  //     [1, // NOSONAR
  //       conso.ConfortTX*100, conso.ConfortTX > 0.06 ? (conso.ConfortTX*100).round(1)+'%' : null, `<${consigne}°C (${(conso.ConfortTX*100).round(1)}%)`, // NOSONAR
  //       conso.InteriorTX*100, conso.InteriorTX > 0.06 ? (conso.InteriorTX*100).round(1)+'%' : null, `${consigne}°C-${Tre2020}°C (${(conso.InteriorTX*100).round(1)}%)`, // NOSONAR
  //       conso.DisconfortTX*100, conso.DisconfortTX > 0.06 ? (conso.DisconfortTX*100).round(1)+'%' : null, `>${tempMax}°C (${(conso.DisconfortTX*100).round(1)}%)`, // NOSONAR
  //       reference*100, _T('PIECHART_RAF_RT2020', {temp: Tre2020}) + ` ${parseInt(reference*100)}%` , // NOSONAR
  //       conso.ConfortTX*100, _T('PIECHART_RAF_TEMP_SET', {temp: consigne}) + ` ${parseInt(conso.ConfortTX*100)}%`], // NOSONAR
  //     [3, null, null, null, null, null, null, null, null, null, reference*100, null, conso.ConfortTX*100, null], // NOSONAR
  //   ] // NOSONAR

  /**
   * if(!showZone2) {
   * chartData.forEach(function(cd){
   *  cd.splice(10,2)
   *   cd.splice(4,3)
   * })
   * }
   * 
   * if(!showZone2) {
   *  chartData.forEach(function(cd){
   *    cd.splice(10,2)
   *   cd.splice(4,3)
   *  })
   * }
   * 
   *  const data = new window.google.visualization.arrayToDataTable(chartData)
   *  const formatter = new window.google.visualization.NumberFormat({
   *  fractionDigits: 0,
   *    suffix: '%'
   *  })
   *  formatter.format(data, 1)
   *
   *  var chartDiv = document.getElementById('chartCoolMode')
   *  const chart = new window.google.visualization.ComboChart(chartDiv)
   */

  // chart.draw(data, { // NOSONAR
  //   ...barChartOptions, // NOSONAR
  //   ...{ // NOSONAR
  //     colors: showZone2 ? CHART_COLOR_3: CHART_COLOR_3b, // NOSONAR
  //     series: showZone2 ? {3: {type: 'line'}, 4: {type: 'line', lineDashStyle: [4, 4]}} : {2: {type: 'line', lineDashStyle: [4, 4]}}, // NOSONAR
  //   } // NOSONAR
  // }) // NOSONAR
  // } // NOSONAR

  // google.charts.load('current', {packages: ['corechart'], language: UIStore.lang}) // NOSONAR
  // google.charts.setOnLoadCallback(() => drawChartCoolMode(product.E5)) // NOSONAR

  return (
    <>
      <div className="row chartRow">
        <div className="col col-12">
          <div className="chart" id="chartCoolMode" />
        </div>
      </div>
      <div className="row chartRow">
        <div className="col col-12">
          <div className="chartNote">
            <button className="help" style={{left: 20, right: 'auto'}} id='helpPieChart'></button>
            <HelpPophover targetId='helpPieChart'>
              <pre dangerouslySetInnerHTML={{__html: _T('HELP_PIE_CHART')}}></pre>
              <div></div>
            </HelpPophover>

            { _THtml('PIECHART_RAF_TEXT', {
              jour1: product.StartDay, // NOSONAR
              mois1: product.StartMonth, // NOSONAR
              jour2: product.EndDay, // NOSONAR
              mois2: product.EndMonth, // NOSONAR
              jours: product.NbDays // NOSONAR
            }) }
          </div>
        </div>
      </div>
    </>
  );
}

export default  observer(ProductModalContent7);