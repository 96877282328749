import {makeObservable,  observable, computed, action } from 'mobx';
import {getBreadcrumbItems} from "../configApp";
import UISTore from './UIStore';
import * as traceService from '../Project/projectTrace.service';
import { _T } from '../_i18n/i18n.helper';
import uuid from 'uuid';
import _ from 'lodash';

class ProjectStore {

  traceId = null;
  minStep = 0;
  maxStep = 10;

  defaultForm = {
    id: null,
    uid: uuid.v4(),
    header: null,
    forms: {
      heatModeStep: {
        data: null,
        form: null,
      },
      coolModeStep: {
        data: null,
        form: null,
      },
      hpChoiceStep: {
        data: null,
        form: null
      }
    },
    quote: {
      lines: [],
      otherlines: [],
      vat: null,
      total: 0,
      totalHT: 0,
      comment: null
    },
    simulationHot: true,
    simulationCool: false,
    deperds: null,
    results: null,
    selectedProduct: null,
    schemaCode: null,
    accessories: null,
    currentStep: 0,
    maxReachedStep: 0,
    newMaximumTempTransmitter: null,
  };

  project = {...this.defaultForm};

  get isHybride() {
    return this.project.forms.hpChoiceStep.form.heatPumpType === '3'
  }

  get alllQuoteLines() {
    return this.project.quote.lines.slice().concat(this.project.quote.otherlines.slice());
  }

  constructor() {
    makeObservable(this, {
      project: observable,
      isHybride: computed,
      alllQuoteLines: computed,
      initNewProject: action,
      saveStore: action,
      updateForm: action,
      nextStep: action,
      previousStep: action,
      goToStep: action,
      changeStep: action,
      selectProduct: action,
      setSchemaCode: action,
      setAccessories: action,
      load: action,
      setQuoteLines: action,
      setVat: action,
      setQuoteTotal: action,
      setQuoteTotalHT: action,
      setNewTransmitterTemp: action,
      setHeader: action,
      resetResults: action,
      setComment: action,


    })

    this.traceId = localStorage.getItem('traceId');

    let project = localStorage.getItem('currentProject');
    if(project) {
      project = JSON.parse(project);
      /**
       * for (const form in project.forms) {
       * if (project.forms.hasOwnProperty(form)) {
       *   this.project.forms[form] = project.forms[form]
       * }
      * }
      */
      this.project = project;
    }
  }

  /**
   * Return translated value or value of a dropdown list selectd item
   * @param path
   * @returns {*}
   */
  value(path) {
    let value = _.get(this.project, path + '_i18nCode');
    if(value != null) {
      return _T(value);
    }

    value = _.get(this.project, path + '_value');
    if(value != null) {
      return value;
    }

    // Return code if no value found
    return _.get(this.project, path) || 0;
  }

  initNewProject() {
    let project = localStorage.getItem('currentProject');
    if(project) {
      localStorage.setItem('lastProject', project);
    }
    localStorage.removeItem('currentProject');
    this.project = {...this.defaultForm}
  }

  saveStore() {
    localStorage.setItem('currentProject', JSON.stringify(this.project));
  }

  updateForm (form, data) {
    this.project.forms[form] = data;
    this.saveStore();
  }

  nextStep(history) {
    if(this.project.currentStep === 0 && history.location.pathname.indexOf('/home/heat') > 0 && this.project.simulationCool) {
      history.push(`/${UISTore.env}/project/home/cool`);
      window.scrollTo(0,0);
      return;
    }

    const nextStep = Math.min(this.maxStep, this.project.currentStep+1);
    if(nextStep > this.project.currentStep) {
      const found = getBreadcrumbItems(UISTore.environment_parameters.disabledSteps)[nextStep];
      if(found) {
        this.changeStep(nextStep);
        history.push(`/${UISTore.env}/project/` + found.id + (found.params ? `/${found.params}` : ''));
        window.scrollTo(0,0);
      }
    }
  }

  previousStep(history) {
    const previousStep = Math.max(this.minStep, this.project.currentStep-1);
    if(previousStep < this.project.currentStep) {
      const found = getBreadcrumbItems(UISTore.environment_parameters.disabledSteps)[previousStep];
      if(found) {
        this.changeStep(previousStep);
        history.push(`/${UISTore.env}/project/` + found.id + (found.params ? `/${found.params}` : ''));
        window.scrollTo(0,0);
      }
    }
  }

  goToStep(index, history) {
    let found = getBreadcrumbItems(UISTore.environment_parameters.disabledSteps)[index];
    if(!found) {
      found = getBreadcrumbItems(UISTore.environment_parameters.disabledSteps)[0];
    }

    this.changeStep(found.index);
    history.push(`/${UISTore.env}/project/` + found.id + (found.params ? `/${found.params}` : ''));
  }

  changeStep(index) {
    if(this.project.currentStep !== index) {
      this.project.currentStep = Math.max(this.minStep, Math.min(this.maxStep, index));
      this.project.maxReachedStep = Math.max(index, this.project.maxReachedStep);

      if (index > 3 && this.traceId) {
        traceService.update();
      }
    }
  }

  selectProduct(product) {
    this.project.selectedProduct = product;
    this.setAccessories(null);
  }

  setSchemaCode(code) {
    this.project.schemaCode = code;
  }

  setAccessories(accessories) {
    this.project.accessories = accessories;
  }

  load(project) {
    this.project = {...project.data, id: project.id};
    this.saveStore();
  }

  setQuoteLines(lines) {
    if(!this.project.quote) {
      this.project.quote = {...this.defaultForm.quote};
    }
    this.project.quote.lines = lines.filter(line => !line._custom);
    this.project.quote.otherlines = lines.filter(line => line._custom);

    // Update accessories props with change made in quote
    ['recommendedAccessories', 'otherAccessories'].forEach(accType => {
      this.project.accessories[accType].forEach(acc => {
        let found = this.project.quote.lines.filter(qacc => qacc._type === 'accessories').find(qacc => qacc.id === acc.id);
        if (found) {
          acc._selected = found._selected;
          acc._qty = found._qty;
        }
      });
    });
  }

  setVat(vat) {
    this.project.quote.vat = vat;
  }

  setQuoteTotal(total) {
    this.project.quote.total = total;
  }
  setQuoteTotalHT(totalHT) {
    this.project.quote.totalHT = totalHT;
  }

  setComment(comment) {
    this.project.quote.comment = comment;
  }

  resetResults() {
    this.project.results = null;
  }

  setHeader(header) {
    this.project.header = header;
  }

  setNewTransmitterTemp(temp) {
    this.project.newMaximumTempTransmitter = temp;
  }

}

// const proj = new ProjectStore()

export default new ProjectStore();